<template>
    <div class="col-lg-12 col-md-12 col-xs-12">
        <div class="row product-category">
            <div class="col-6 col-md-4 col-lg-2">
                <p>THERMAL</p>
                <a href="/products/thermal">
                    <img class="w-100" src="../assets/images/home/thermal.jpg" alt="THERMAL">
                </a>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <p>DIET</p>
                <a href="/products/diet">
                    <img class="w-100" src="../assets/images/home/diet.jpg" alt="DIET">
                </a>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <p>ARTIFICIAL INSEMINATION</p>
                <a href="/products/artificial-insemination">
                    <img class="w-100" src="../assets/images/home/artificial-insemination.jpg" alt="ARTIFICIAL INSEMINATION">
                </a>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <p>MEDICAL</p>
                <a href="/products/medical">
                    <img class="w-100" src="../assets/images/home/medical.jpg" alt="MEDICAL">
                </a>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <p>COOLING SYSTEM</p>
                <a href="/products/thermal">
                    <img class="w-100" src="../assets/images/home/cooling-system.jpg" alt="COOLING SYSTEM">
                </a>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <p>OTHERS</p>
                <a href="/products/others">
                    <img class="w-100" src="../assets/images/home/others.jpg" alt="OTHERS">
                </a>
            </div>
        </div>

        <div class="row fast-btn">
            <div class="col-12 col-md-10 offset-md-1">
                <div class="row">
                    <div class="col-4">
                        <div class="row">
                            <div class="col-12 col-md-5">
                                <img src="../assets/images/home/fast1.jpg" alt="">
                            </div>
                            <div class="col-12 col-md-7 p-3 p-md-0">
                                <h3 class="text-break">ABOUT MAXSTOCK</h3>
                                <p class="d-none d-sm-block">Maxstock established in 1981, has more than 30 years.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <div class="col-12 col-md-5">
                                <a href="/information">
                                    <img src="../assets/images/home/fast2.jpg" alt="" />
                                </a>
                            </div>
                            <div class="col-12 col-md-7 p-3 p-md-0">
                                <h3 class="text-break">INFO.& TIPS</h3>
                                <p class="d-none d-sm-block">Product features: rugged, recyclable and part sharing.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <div class="col-12 col-md-5">
                                <a href="mailto: maxstock.tw@gmail.com">
                                    <img src="../assets/images/home/fast3.jpg" alt="" />
                                </a>
                            </div>
                            <div class="col-12 col-md-7 p-3 p-md-0">
                                <h3 class="text-break">CONTACT US</h3>
                                <p class="d-none d-sm-block">Please let me know if you have any questions or concerns.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row nf-block">
            <div class="col-12 col-md-4 offset-md-2">
                <div class="nf-left-content">
                    <h3>NEWS</h3>
                    <div class="nf-left-inner">
                        <ul>
                            <li>
                                <a href="/information">2017.03.15 Exhibition</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="nf-right-content">
                    <a href="https://www.facebook.com/maxstockequipment/">
                        <img src="../assets/images/home/fb.jpg" alt="">
                    </a>
                    <div class="nf-right-inner">
                        <h3>Maxstock</h3>
                        <p>Officail Facebook</p>
                        <p>Welcome to The Maxstock Facebook - committed to providing all kinds of animal husbandry equipment.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row map">
            <div class="col-12 col-md-6 map-left">
                <div id="map">
                    <GMapMap
                        :center="{lat: 23.957994, lng: 120.475891}"
                        :zoom="17"
                        style="height: 500px"
                        :options="options"
                    >
                        <GMapMarker
                            :position="position"
                            :icon="require('../assets/images/home/map-index.png')"
                        />
                    </GMapMap>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row map-right">
                    <div class="col-12 col-md-6 offset-md-2 company-info">
                        <h3>Company Info.</h3>
                        <div>
                            <img src="../assets/images/home/locate.png" alt="">
                            <span>51443 No.52, Huazhong St., Xihu Township, Changhua County 514, Taiwan (R.O.C.)</span>
                        </div>
                        <div>
                            <img src="../assets/images/home/phone.png" alt="">
                            <span>+886-4-8857089
                                +886-4-8813667
                            </span>
                        </div>
                        <div>
                            <img src="../assets/images/home/mail.png" alt="">
                            <span>maxstock.tw@gmail.com</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 qr-code">
                        <img src="../assets/images/home/qr.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: 'Home',
    data () {
        return {
            position: {
                lat: 23.957994, lng: 120.475891
            },
            options: {
                styles: [{
                    "stylers": [
                        { "saturation": -100 }
                    ]
                }]
            }
        }
    }
}
</script>

<style scoped lang="less">
.product-category {
    > div {
        padding: 0;
    }

    div:nth-child(1) {
        background-color: #de6b37;
    }
    div:nth-child(2) {
        background-color: #8a010b;
    }
    div:nth-child(3) {
        background-color: #3693a9;
    }
    div:nth-child(4) {
        background-color: #01553b;
    }
    div:nth-child(5) {
        background-color: #3f3628;
    }
    div:nth-child(6) {
        background-color: #5c452e;
    }

    p {
        font-size: 1em;
        margin: 0;
        height: 55px;
        padding-top: 15px;
        padding-left: 20px;
        color: white;
        text-align: start;
    }

    img {
        width: 100%;
    }
}

.fast-btn {
    padding: 50px 0;
    background-color: #f2f2f2;

    img {
        border-radius: 50%;
        margin-top: 5px;
        width: 100%;
    }

    h3 {
        font-family: BellMT;
        font-size: 1.5em;
        margin: 10px 0;
        text-align: start;
    }

    p {
        font-size: 1em;
        text-align: start;
    }
}

.nf-block {
    margin: 100px 0;
    text-align: start;

    .nf-left-content {
        float: right;

        h3, .nf-left-inner {
            display: inline-block;
        }

        .nf-left-inner {
            border-left: 1px solid black;
            padding-left: 20px;
        }

        h3 {
            vertical-align: top;
            margin: 0;
            padding-right: 20px;
        }

        ul {
            list-style: none;
            padding: 0;
        }

        li:hover {
            color: gray;
        }
    }

    .nf-right-content {
        border-left: 1px solid black;
        padding-left: 20px;
        margin-left: 50px;

        a, .nf-right-inner {
            display: inline-block;
            vertical-align: top;
        }

        .nf-right-inner {
            width: 60%;
        }

        h3 {
            margin: 0;
        }
    }
}

.map {
    background-color: #f2f2f2;
    display: flex;
    align-items: center;

    > div {
        padding: 0;
    }

    .map-left {
        height: 500px;
    }

    .map-right {
        width: 100%;
        display: flex;
        align-items: flex-end;

        h3 {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    .company-info {
        text-align: start;

        img, span {
            display: inline-block;
        }

        img {
            vertical-align: top;
            padding-right: 5px;
        }

        span {
            width: 90%;
            white-space: pre-line;
            padding-bottom: 10px;
        }
    }
}

#map {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 767px) {
    .fast-btn {
        text-align: center;
    }

    .map {
        display: block;
    }

    .nf-block {
        margin: 10px 0;
    }

    .nf-block .nf-left-content {
        clear: both;
        margin: 2.5em 2.5em;
    }

    .nf-block .nf-right-content {
        border: none;
        padding: 0;
        margin-left: 2.5em;
    }
    .qr-code {
        display: none;
    }
    .company-info {
        margin: 2em;
    }
    .nf-right-content img {
        margin-right: 10px;
    }
    .nf-block .nf-left-content {
        float: none;
        margin: 2.5em;
    }
}
</style>
